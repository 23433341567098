<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <b-table
      v-if="documents"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="name"
      :data="documents"
    >
      <b-table-column
        custom-key="actions"
        cell-class="is-actions-cell"
        v-slot="props"
      >
        <div class="buttons is-left">
          <router-link
            :to="{
              name: 'urbanismdocument.edit',

              params: { id: props.row.id },
            }"
            class="button is-small is-primary"
          >
            <b-icon icon="file-edit" size="is-small" />
          </router-link>

          <button
            class="button is-small is-danger"
            type="button"
            @click.prevent="trashModal(props.row)"
            v-if="canDelete(props.row)"
          >
            <b-icon icon="trash-can" size="is-small" />
          </button>
        </div>
      </b-table-column>
      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column">
          <template
            v-if="column.searchable && !column.numeric"
            #searchable="props"
          >
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Cauta..."
              icon="magnify"
              size="is-small"
            />
          </template>
          <template v-if="column.date" v-slot="props">
            {{ props.row[column.field] | formatDate }}
          </template>
          <template v-else v-slot="props">
            {{ props.row[column.field] }}
          </template>
        </b-table-column>
      </template>
      <b-table-column
        field="status"
        label="Status"
        sortable
        centered
        v-slot="props"
      >
        <span :class="getComputedStyle(props.row)">
          {{ props.row.status }}
        </span>
      </b-table-column>
      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Incarcare data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nici o inregistrare&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>
<script>
import ModalBox from "@/components/ModalBox";

export default {
  name: "UrbanismDocumentsTable",
  components: { ModalBox },
  props: {
    documents: {
      type: Array,
      default: null,
    },
    maxEntryCertificate: {
      type: Number,
      default: 0,
    },
    maxEntryAuthorization: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isModalActive: false,
      trashObject: null,
      trashObjectName: null,
      isLoading: false,
      paginated: true,
      perPage: 10,
      checkedRows: [],
      columns: [
        {
          field: "id",
          label: "ID",
          numeric: true,
          visible: false,
        },
        {
          field: "applicantName",
          label: "Nume applicant",
          numeric: false,
          sortable: true,
          searchable: true,
          visible: true,
        },
        {
          field: "purpose",
          label: "Scop",
          numeric: false,
          sortable: true,
          searchable: true,
        },
        {
          field: "registrationNumber",
          label: "Numar inregistrare",
          numeric: false,
          sortable: true,
          searchable: true,
        },
        {
          field: "documentTypeString",
          label: "Tip document",
          numeric: false,
          sortable: true,
          searchable: false,
        },
        {
          field: "registrationDate",
          label: "Data eliberare",
          numeric: false,
          date: true,
          sortable: false,
          searchable: false,
        },
        {
          field: "expirationDate",
          label: "Data expirare",
          numeric: false,
          date: true,
          sortable: false,
          searchable: false,
        },
      ],
    };
  },
  methods: {
    canDelete(row) {
      return (
        (row.registrationNumber === this.maxEntryAuthorization && row.documentType === 1) ||
        (row.registrationNumber === this.maxEntryCertificate && row.documentType === 0)
      );
    },
    getComputedStyle(document)
    {
      if (document.status === "Valid") return "tag is-success";
      if (document.status === "In curs de expirare") return "tag is-warning";
      if(document.status === "Expirat") return 'tag is-danger';
    },
    trashModal(trashObject) {
      this.trashObject = trashObject;
      this.isModalActive = true;
    },
    trashConfirm() {
      this.isModalActive = false;
      this.$emit("handleDeleteUrbanismDocument", this.trashObject.id);
    },
    trashCancel() {
      this.isModalActive = false;
    },
  },
};
</script>
