<template>
  <section class="hero is-hero-bar is-main-hero">
    <title-bar :title-stack="titleStack" />
    <div class="hero-body">
      <div class="columns">
        <div class="column">
          <div class="buttons">
            <b-button
              icon-left="file-plus"
              class="button is-primary"
              @click="handleAddDocument"
            >
              Inregistrare noua
            </b-button>
            <b-button v-if="false" icon-left="refresh" class="button is-success"
              >Reincarcare date
            </b-button>
            <b-button
              v-if="false"
              :class="isFilterSelected ? 'button is-warning' : 'button is-info'"
              @click="isFiltersOpened = !isFiltersOpened"
              :icon-left="
                !isFiltersOpened ? 'chevron-double-down' : 'chevron-double-up'
              "
            >
              <!-- <b-icon :icon="custom - size" ="default" /> -->
              <p v-if="isFilterSelected">
                Filtre (Exista filtre aplicate) -
                {{ this.documents.length }} inregistrari afisate
              </p>
              <p v-else>
                Filtre (Nu exista filtre aplicate) -
                {{ this.documents.length }} inregistrari afisate
              </p>
            </b-button>
            <b-button
              v-if="false"
              icon-left="printer"
              class="button is-primary"
            >
              Imprimare
            </b-button>
            <download-excel
              v-if="false"
              :data="this.documents"
              :fields="json_fields"
              name="Documente_urbanism_export.xls"
              worksheet="Documente"
            >
              <b-button
                icon-left="file-excel"
                class="button is-primary"
                v-if="false"
              >
                Export .xls (format Excel)
              </b-button>
            </download-excel>
          </div>
        </div>
      </div>
    </div>
    <UrbanismdocumentFilter
      class="mt-5"
      :propIsOpened="isFiltersOpened"
      :propIsSelected="isFilterSelected"
    ></UrbanismdocumentFilter>
    <card-component
      class="has-table has-mobile-sort-spaced"
      title="Documente"
      icon="account-multiple"
    >
      <urbanismDocuments-table
        :documents="documents"
        @handleDeleteUrbanismDocument="handleDeleteUrbanismDocument"
        :maxEntryCertificate="maxEntryCertificate"
        :maxEntryAuthorization="maxEntryAuthorization"
      ></urbanismDocuments-table>
    </card-component>
  </section>
</template>

<script>
import Urbanism from "@/services/urbanism.service";
const _ = require("lodash");
import CardComponent from "@/components/CardComponent";
import TitleBar from "@/components/TitleBar";
import UrbanismDocumentsTable from "@/components/urbanism/urbanismdocuments-table.vue";
import UrbanismdocumentFilter from "../components/urbanism/urbanismdocument-filter.vue";

export default {
  name: "Urbanism",
  components: {
    TitleBar,
    CardComponent,
    UrbanismDocumentsTable,
    UrbanismdocumentFilter,
  },
  data() {
    return {
      documents: [],
      isFiltersOpened: false,
      isFilterSelected: false,
      maxEntryCertificate: 0,
      maxEntryAuthorization: 0,
    };
  },
  created() {
    this.getAllDocuments();
  },
  methods: {
    async getAllDocuments() {
      const { getDocuments } = Urbanism;
      getDocuments().then((response) => {
        this.documents = response.data;
        var authorizationDocumentArray = this.documents.filter(function (el) {
          return el.documentType === 1;
        });

        var certificateDocumentArray = this.documents.filter(function (el) {
          return el.documentType === 0;
        });

        const maxAuthorization = authorizationDocumentArray.reduce(
          (acc, shot) =>
            (acc =
              acc > shot.registrationNumber ? acc : shot.registrationNumber),
          0
        );
        const maxCertificate = certificateDocumentArray.reduce(
          (acc, shot) =>
            (acc =
              acc > shot.registrationNumber ? acc : shot.registrationNumber),
          0
        );
        this.maxEntryCertificate = maxCertificate;
        this.maxEntryAuthorization = maxAuthorization;
      });
    },
    async handleAddDocument() {
      this.$router.push({
        path: "/urbanismdocuments/add",
      });
    },
    async handleDeleteUrbanismDocument(id) {
      const { deleteDocument } = Urbanism;
      const response = await deleteDocument(id);
      const { data, status } = response;
      if (status == "200") {
        const array = this.documents;
        const index = this.documents.findIndex((prop) => prop.id === id);
        this.documents.splice(index, 1);
        this.$buefy.snackbar.open({
          message: "Inregistrea a fost stearsa.",
          queue: false,
        });
        this.getAllDocuments();
      } else {
        this.$buefy.snackbar.open({
          message: "Eroare la modificarea inregistrarii",
          queue: false,
        });
      }
    },
  },
  computed: {
    titleStack() {
      return ["Registru Urbanism", "Documente"];
    },
    heroRouterLinkTo() {
      return { name: "/volunteers/add" };
    },
  },
};
</script>
