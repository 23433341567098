<template>
    <b-collapse animation="slide" v-model="isOpened">
      <div class="card">
        <div class="card-header">
          <p class="card-header-title">
            <b-icon icon="filter-minus-outline" custom-size="default" />
            <span>Filtre</span>
          </p>
        </div>
        <div class="card-content">
          <ValidationObserver ref="form">
            <div class="columns">
              <div class="column is-4">
                <div class="columns">
                  <div class="column is-narrow">
                    <ValidationProvider
                      name="Filtrare in functie de status"
                      v-slot="{ errors }"
                    >
                      <b-field
                        :type="errors[0] ? 'is-danger' : ''"
                        :message="errors[0]"
                        label="Filtrare in functie de status"
                      >
                        <div class="columns">
                          <b-radio
                            v-model="filters.status"
                            name="status"
                            native-value="Rezolvat"
                            class="column is-narrow"
                            @input="applyFilters()"
                          >
                            Expirat
                          </b-radio>
                          <b-radio
                            v-model="filters.status"
                            name="status"
                            native-value="În lucru"
                            class="column is-narrow"
                            @input="applyFilters()"
                          >
                            În curs de expirare
                          </b-radio>
                        </div>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="column is-7">
                <div class="columns">
                  <div class="column">
                    <ValidationProvider
                      name="Filtrare in functie de data"
                      v-slot="{ errors }"
                    >
                      <b-field
                        :type="errors[0] ? 'is-danger' : ''"
                        :message="errors[0]"
                        label="Filtrare in functie de tip document"
                      >
                        <div class="columns">
                          <b-radio
                            v-model="filters.response"
                            name="response"
                            native-value="noResponse"
                            class="column is-12-mobile is-narrow-tablet"
                            @input="applyFilters()"
                          >
                            Autorizatie
                          </b-radio>
                          <b-radio
                            v-model="filters.response"
                            name="response"
                            native-value="withResponse"
                            class="column is-12-mobile is-narrow-tablet"
                            @input="applyFilters()"
                          >
                            Certificat
                          </b-radio>
                        </div>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </ValidationObserver>
          <div class="columns">
            <div class="column">
              <b-field grouped>
                <div class="control">
                  <b-button type="is-danger is-outlined" @click="cancelFilters()"
                    >Anulare filtre</b-button
                  >
                </div>
                <div class="control">
                  <b-button type="is-warning is-outlined" @click="resetFilters()"
                    >Resetare filtre</b-button
                  >
                </div>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </template>
  
  <script>
  export default {
    name: "UrbanismDocumentFilter",
    props: {
      propIsOpened: Boolean,
      propIsSelected: Boolean,
    },
    data() {
      return {
        filters: {
          dateInterval: null,
          status: null,
          fromDate: null,
          toDate: null,
          deadlineInterval: null,
          response: null,
        },
      };
    },
    created() {
      if (localStorage["filters"] != undefined) {
        let storageLocal = JSON.parse(localStorage.getItem("filters"));
        this.filters = storageLocal;
      }
      if (
        this.filters.dateInterval == null &&
        this.filters.fromDate == null &&
        this.filters.status == null &&
        this.filters.toDate == null &&
        this.filters.response == null &&
        this.filters.deadlineInterval == null
      ) {
        this.isSelected = false;
      } else {
        this.isSelected = true;
      }
    },
    computed: {
      isOpened: {
        get() {
          return this.propIsOpened;
        },
        set(val) {
          this.$emit("update-opened-value", val);
        },
      },
      isSelected: {
        get() {
          return this.propIsSelected;
        },
        set(val) {
          this.$emit("update-selected-value", val);
        },
      },
    },
    methods: {
      parser(d) {
        return new Date(Date.parse(d));
      },
      dateFromSelected() {
        this.applyFilters();
      },
      dateToSelected() {
        this.applyFilters();
      },
      resetFilters() {
        this.isSelected = false;
        this.filters.dateInterval = null;
        this.filters.status = null;
        this.filters.custom = null;
        this.filters.fromDate = null;
        this.filters.toDate = null;
        this.filters.deadlineInterval = null;
        this.filters.response = null;
        this.$emit("filterRegistryEntries", this.filters);
      },
      cancelFilters() {
        this.isOpened = false;
        this.isSelected = false;
        this.filters.dateInterval = null;
        this.filters.status = null;
        this.filters.custom = null;
        this.filters.fromDate = null;
        this.filters.toDate = null;
        this.filters.deadlineInterval = null;
        this.filters.response = null;
        this.$emit("filterRegistryEntries", this.filters);
      },
      applyFilters() {
        this.isSelected = true;
        if (this.filters.fromDate) {
          this.filters.fromDate = new Date(
            this.filters.fromDate.getTime() -
              this.filters.fromDate.getTimezoneOffset() * 60000
          ).toJSON();
        }
        if (this.filters.toDate) {
          this.filters.toDate = new Date(
            this.filters.toDate.getTime() -
              this.filters.toDate.getTimezoneOffset() * 60000
          ).toJSON();
        }
  
        this.$emit("filterRegistryEntries", this.filters);
      },
    },
  };
  </script>
  